.App {
  text-align: center;
}

.App {
  background-image: #linear-gradient(
      to bottom,
      rgba(250, 253, 247, 0) 80%,
      var(--bg-light)
    ),
    url("assets/background.webp");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.form {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f26301;
  height: 100px;
  padding: 2rem;
  border-radius: 10px;
  margin: 1rem;
  color: white;
  cursor: pointer;
}
.button {
  background-color: #f26301;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #f26301;
  width: 50%;
  cursor: pointer;
  margin: 1rem;
}
#progressContainer {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}
.progress-bar {
  background-color: #ddd;
  border-radius: 5px;
  margin: 10px 0;
  padding: 3px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.button:hover {
  background-color: #f26301;
}
.error {
  color: red;
  font-size: 0.8rem;
  margin: 0.5rem;
}
